@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
  @apply font-titillium;
}

.default-font {
  @apply font-titillium;
}

body {
  margin: 0;
  height: 100%;
}

p, span, a {

}

code {

}
@media (max-width: 768px) {
  .table-container {
    max-width: 100%;
  }
}


@media (max-width: 900px) {
  .nav-desktop-menu {
    display: none;
  }
  .mass-balance {
    max-width: 100%;
  }
  .filter-by {
    display: none;
  }
}

@media (min-width: 901px) {
  .nav-mobile-menu {
    display: none;
  }
  .container-mass-balance {
    flex-wrap: nowrap;
  }
  .mass-balance {
    justify-content: flex-start;
  }
}

@media (min-width: 1090px) {
  .balance-container {
    flex-wrap: nowrap;
    width: 100%;
  }
  .balance-card {
    min-width: 335px;
  }
  .curve-segregation {
    flex-wrap: nowrap;
  }
}

@media (min-width: 1280px) {
  .card-commercial-exportation {
    flex-wrap: nowrap;
  }
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #FEFEFE;
}

main {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
}

.login-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 84vh;
}

.background-color-ddc {
  height: 50%;
  background-image: url('../src/assets/images/bg-ddc.png');
  background-size: cover;
  background-position: center;
}

.background-gray {
  height: 50%;
  background-color: #F3F3F3;
}

.terms-modal {
  position: fixed;
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  z-index: 10;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  height: 80%; 
  display: flex;
  flex-direction: column;
}

.modal-header {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.modal-body {
  flex-grow: 1; 
  overflow-y: auto; 
  margin-bottom: 20px; 
  font-size: 1rem;
  line-height: 1.6;
  padding: 10px;
  color: #333;
}

.modal-body p {
  margin-bottom: 1.5em; 
}

.modal-body ol, .modal-body ol {
  margin-left: 20px;
  margin-bottom: 2em; 
}

ol {
  list-style-type:disc; 
  margin-left: 20px; 
}

ol li {
  margin-bottom: 10px; 
}

.modal-body li {
  margin-bottom: 0.8em; 
}

.modal-body h3, .modal-body h4 {
  margin-top: 1.5em; 
  font-size: 1.25em;
  font-weight: bold;
}

.modal-body h4 {
  margin-top: 1em;
  font-size: 1.125em;
}

.modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content:center;

}

.modal-actions button {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
}

.buttonAccept {
  background-color: #01AAEF;
  color: white;
  border: none;
  border-radius: 5px;
  transition: ease-in-out 0.3s;
}

.buttonAccept:hover {
  background-color: #0e12f7;;
  color: white;
  border: none;
  border-radius: 5px;
}

.buttonCancel {
  background-color: #ff5441;
  color: white;
  border: none;
  border-radius: 5px;
  transition: ease-in-out 0.3s;
}

.buttonCancel:hover {
  background-color: #bd1300;
  color: white;
  border: none;
  border-radius: 5px;
}

.warning-modal {
  font-family: 'Titillium Web', sans-serif;
  position: fixed;
  padding: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
  font-size: 20px;
}

.warning-modal .modal-body {
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  line-height: 1.6;
  padding: 15px 10px;
  text-align: center;
  color: #333;
  border-radius: 5px;
  max-width: 600px;
  max-height: 500px;
  margin-bottom: 15px;
}

.warning-modal button {
  font-family: 'Titillium Web', sans-serif;
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
}
.warning-modal .modal-footer {
  display: flex;
  justify-content: center;
  gap: 9px;
}

@media (max-width: 20000px) {
  .warning-modal .modal-content {
    max-width: auto;
    height: auto;
  }

  .warning-modal .modal-body {
    font-size: 0.9rem;
  }
}

.table-container {
  overflow-x: auto; 
  max-width: 100%; 
}

.sticky-column {
  position: sticky;
  left: -20px; 
  z-index: 5; 
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); 
}
.sticky-header {
  position: sticky;
  top: 0; 
  left: -20px; 
  z-index: 5; 
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  background-color: #e5ebf6; 
}

.overflow-x-auto::-webkit-scrollbar {
  height: 10px; 
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background-color: #c1c1c1; 

}

.overflow-x-auto::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
}

.overflow-x-auto {
  overflow-x: scroll; 
  -webkit-overflow-scrolling: touch;
}


.Widget {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 7px 0px #5353530D;
}

footer {
  width: 100%;
  text-align: center;
  padding: 10px;
  background-color: #ccc;
  position: relative;
  bottom: 0;
}

.dev-font-f-roboto
{

}

.dev-font-f-titi-web
{

}

.dev-ddc-alert
{
  position: absolute;
  top: -10%;
  right: 0;
}

/*start fonts*/

/*titillium*/

.titillium-web-extralight {
  font-family: "Titillium Web", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.titillium-web-light {
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.titillium-web-regular {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.titillium-web-semibold {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.titillium-web-bold {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.titillium-web-black {
  font-family: "Titillium Web", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.titillium-web-extralight-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.titillium-web-light-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.titillium-web-regular-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.titillium-web-semibold-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.titillium-web-bold-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-style: italic;
}

/*titillium*/

/*roboto*/

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}
/*roboto*/

/*inter*/
.inter {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
/*inter*/

/*end fonts*/

/*start header page*/
.page-title {
  @apply font-titillium;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
}

.page-subtitle {
  @apply font-roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}
/*end header page*/

/*start breadcrumbs*/
.breadcrumb {
  list-style: none;
  display: flex;
  @apply font-inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

.breadcrumb-item {
  margin-right: 8px;
  @apply font-roboto;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: #525252;
}

.active {
  font-weight: bold;
  color: #003DA5;
}
/*end breadcrumbs*/

.button-filter-option {
  @apply font-roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.2770838737487793px;
  text-align: left;
  height: 36px;
  gap: 0;
}

.btn-reset-filter {
  @apply font-roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.icon-chart-blue {
  background: linear-gradient(90deg, #01AAEF 0.16%, #2C2D65 112.38%);
  width: 43px;
  height: 43px;
  padding: 10px;
  border-radius: 50px;
  border: solid 2px white;
  box-shadow: 0 0 0 2px #12C5DE;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-chart-red {
  background: #FF6565;
  width: 43px;
  height: 43px;
  padding: 10px;
  border-radius: 50px;
  border: solid 2px white;
  box-shadow: 0 0 0 2px #FF4848;
  display: flex;
  justify-content: center;
  align-items: center;
}

.balance-card {
  min-width: 335px;
}
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #00C7B1; /* Color del spinner */
  width: 24px; /* Tamaño del spinner */
  height: 24px; /* Tamaño del spinner */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.animate-slide-out {
  animation: slide-out 0.5s ease-in-out;
}