/* Snackbar.css */
.snackbar {
    position: fixed;
    top: 20px; /* Ajusta este valor según necesites */
    right: 20px; /* Ajusta este valor según necesites */
    background-color: #323232; /* Color de fondo */
    color: white; /* Color del texto */
    padding: 16px;
    border-radius: 8px;
    font-size: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3); /* Sombra para un efecto más bonito */
    z-index: 1000; /* Asegúrate de que el Snackbar esté por encima de otros elementos */
    opacity: 0; /* Para animación */
    transition: opacity 0.3s ease-in-out; /* Transición suave */
  }
  
  .snackbar.show {
    opacity: 1; /* Muestra el Snackbar */
  }
  
  .snackbar.hide {
    opacity: 0; /* Oculta el Snackbar */
  }
  
  /* Snackbar.css */
  .fixed_mensaje {
    position: fixed;
    transition: all 0.3s ease;
  }
  
  .flex_mensaje {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .bg-blue-500_mensaje {
    background-color: #fff; /* Color azul */
  }
  
  .text-white_mensaje {
    color: black;
  }
  
  .rounded_mensaje {
    border-radius: 0.375rem; /* Bordes redondeados */
  }
  
  .shadow-lg_mensaje {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.1);
  }
  